<template>
  <div class="home">
    <a-spin class="load" v-show="loading" />
    <div class="readImg">
      <div class="readTitle">高考真题</div>
      <div class="readEnglish">College Entrance Examination Questions</div>
    </div>
    <div class="content">
      <HeadTop />
      <ul class="contLeft">
        <li :class="currentIndex==index?'activeLi':''" v-for="(item,index) in list" :key="index" @click="checkname(item,index)">{{item.title}}</li>
      </ul>
      <div class="contRight">
        <div class="dateDiv">
          <div class="dateText">年份</div>
          <div class="dateSelect">
            <ul class="dateSelectUl">
              <li @click="selectYear(item,index)"  :class="yearIndex===index ? 'dateLiActive': 'dataLi'" v-for="(item,index) in newYear" :key="index">{{item.year}}</li>
            </ul>
          </div>
          <div :class="!flag==true?'dateButton':'dateButtonA'" @click="showAll(flag)">
            <span v-if="!flag">展开 <a-icon style="margin-left:5px;" type="down" /></span>
            <span v-if="flag">收起<a-icon style="margin-left:5px;" type="up" /></span>
          </div>
        </div>
        <div>
          <ul class="listUl" v-if="list1!=null&&list1.length>0">
            <li class="listLi" v-for="(item,index) in list1" :key="index">
                <div class="userDetail">
                    <div class="name">{{item.title}}</div>
                    <div class="date">
                      <span>{{format.format(item.date)}}</span>
                    </div>
                </div>
                <div class="dianzan"  @click="goLook(item)">
                  下载
                </div>
            </li>
        </ul>
        <div style="padding-top:90px;" v-else class="listUl">
          <a-empty />
        </div>
        </div>
        <div style="text-align:center;margin:15px 0px;">
        <a-config-provider>
          <a-pagination
            show-quick-jumper
            :default-current="1"
            :page-size="pageSize"
            @change="handleListChange"
            show-less-items
            :total=total />
          </a-config-provider>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import HeadTop from '@/components/HeadTop.vue'
import format from '../../utils/format.js'
import axios from 'axios'
export default {
  name: 'testPaper',
  data () {
    return {
      format,
      resBasePath: this.$resBasePath,
      loading: false,
      flag: false,
      isActive: false,
      year: '',
      open: false,
      currentIndex: 0,
      yearIndex: 0,
      pageSize: 10,
      pageNum: 1,
      classifyId: 0,
      total: 0,
      list: [],
      list1: [],
      dateYear: [
        { year: '2003', id: '2003' }, { year: '2004', id: '2004' }, { year: '2005', id: '2005' }, { year: '2006', id: '2006' },
        { year: '2007', id: '2007' }, { year: '2008', id: '2008' }, { year: '2009', id: '2009' }, { year: '2010', id: '2010' },
        { year: '2011', id: '2011' }, { year: '2012', id: '2012' }, { year: '2013', id: '2013' }, { year: '2014', id: '2014' },
        { year: '2015', id: '2015' }, { year: '2016', id: '2016' }, { year: '2017', id: '2017' }, { year: '2018', id: '2018' },
        { year: '2019', id: '2019' }, { year: '2020', id: '2020' }, { year: '2021', id: '2021' }, { year: '全部', id: '' }
      ].reverse(),
      newYear: [] //
    }
  },
  mounted () {
    this.newYear = this.dateYear.slice(0, 10)
    this.getTallClassifyList()
    this.getTallPage()
  },
  methods: {
    getTallClassifyList () { // 高考分类列表
      this.loading = true
      const _this = this
      axios({
        url: _this.$baseUrl + 'official/examHall/getTallClassifyList',
        method: 'POST',
        data: {}
      }).then(res => {
        if (res && res.data && res.data.resultCode == 0) {
          _this.loading = false
          _this.list = []
          _this.list = res.data.resultData
          _this.list.unshift({id: '',title: '全部'})
        }
      })
    },
    // official/examHall/getTallPage 高考真题列表
    getTallPage () {
      const _this = this
      this.loading = true
      const param = {
        classifyId: _this.classifyId,
        pageSize: _this.pageSize,
        pageNum: _this.pageNum,
        year: _this.year
      }
      axios({
        url: _this.$baseUrl + 'official/examHall/getTallPage',
        method: 'POST',
        data: param
      }).then(res => {
        if (res && res.data && res.data.resultCode == 0) {
          _this.loading = false
          _this.list1 = res.data.resultData.list
          _this.total = res.data.resultData.total
        }
      })
    },
    checkname (item, index) {
      this.currentIndex = index
      this.classifyId = item.id
      this.getTallPage()
    },
    handleListChange (current, pageSize) {
      this.pageNum = current
      this.pageSize = pageSize
      this.getTallPage()
    },
    goPolicy () {
      this.$router.push({ name: 'policy' })
    },
    showAll (flag) {
      if (!flag) {
        this.newYear = this.dateYear
        this.flag = true
      } else {
        this.newYear = this.dateYear.slice(0, 10)
        this.flag = false
      }
    },
    selectYear (item, index) {
      this.year = item.id
      this.yearIndex = index
      this.getTallPage()
    },
    goLook (item) {
      // this.fileUrl = 'https://file.e-snails.com/spath' + item.filePath
      const a = document.createElement('a') // 创建a标签
      a.setAttribute('download', '')// download属性
      a.setAttribute('href', this.resBasePath + '/' + item.filePath)// href链接
      a.click()
    }
  },
  components: {
    HeadTop
  }
}
</script>
<style scoped lang="less">
ul,li{
  list-style: none;
  margin: 0;
  padding: 0;
}
  .home{
    background: #f6f6f6;
    .load{
      position: absolute;
      top: 40%;
      left: 50%;
      transform: translate(50%, 50%);
    }
    .readImg{
      width:100%;
      height:260px;
      background: url('../../assets/img/42.png');
      background-size: 100% 100%;
      position: relative;
      .readTitle{
        text-align: center;
        color: #323232;
        line-height: 55px;
        letter-spacing: 4px;
        font-size: 36px;
        padding-top: 80px;
      }
      .readEnglish{
        color: #3D3D3D;
        line-height: 27px;
        letter-spacing: 2px;
        font-size: 16px;
        text-align: center;
      }
    }
    .content{
      width: 1140px;
      margin: auto;
      .contLeft{
        float: left;
        width: 200px;
        background: #fff;
        padding: 20px 0px;
        min-height: 500px;
        margin-bottom: 30px;
        li{
          width: 100%;
          height: 40px;
          line-height: 40px;
          font-size: 20px;
          text-align: center;
          cursor: pointer;
        }
      }
      .contRight{
        margin-left: 20px;
        width: 920px;
        background: #fff;
        padding: 24px 50px 20px;
        margin-bottom: 30px;
        min-height: 520px;
        float: left;
          .dateDiv{
            display: flex;
            width: 850px;
            color: #7F7F7F;
            font-size: 14px;
            .dateText{
              width: 50px;
              height: 24px;
              text-align: left;
              line-height: 24px;
              border-radius: 12px;
              color:#7f7f7f;
            }
            .dateSelect{
              height: 100%;
              width: 800px;
                .dateSelectUl{
                  display: flex;
                  flex-direction: row;
                  flex-wrap: wrap;
                  .dataLi{
                    display: inline-block;
                    width: 60px;
                    margin: 0px 5px 10px 5px;
                    background: #f6f6f6;
                    color: #000;
                    border-radius: 12px;
                    text-align: center;
                    height: 24px;
                    line-height: 24px;
                    cursor: pointer;
                  }
                }
              }
            .dateButtonA{
              text-align: center;
              width: 85px;
              height: 24px;
              line-height: 24px;
              background: #DB5647;
              border-radius: 12px;
              font-size: 14px;
              color:#fff;
              cursor: pointer;
            }
            .dateButton{
              text-align: center;
              width: 85px;
              height: 24px;
              line-height: 24px;
              background: #f6f6f6;
              border-radius: 12px;
              color:#000;
              cursor: pointer;
              font-size: 14px;
            }
          }
        .listUl{
          min-height: 338px;
          .listLi{
              display: flex;
              border-bottom: 1px solid #eee9de;
              width: 100%;
              padding: 10px 0px;
              display: flex;
              cursor: pointer;
              .userDetail{
                  width: 100%;
                  .name{
                      height: 28px;
                      line-height: 28px;
                      color: #000;
                      font-size: 16px;
                      font-weight: 500;
                  }
                  .date{
                      margin-top: 8px;
                      color: #7F7F7F;
                      font-size: 12px;
                  }
              }
              .dianzan {
                  width: 80px;
                  text-align: center;
                  height: 32px;
                  line-height: 32px;
                  background: #FBEEEC;
                  color: #DB5647;
                  border-radius: 4px;
                  margin-top: 10px;
                  a{
                    color: #DB5647;
                  }

                }
          }
        }
      }
    }
  }
  .activeLi{
    background: #DB5647;
    color: #fff;
  }
  .dateLiActive{
    display: inline-block;
    width: 60px;
    margin: 0px 5px 10px 5px;
    background: #DB5647;
    color: #fff;
    border-radius: 12px;
    text-align: center;
    height: 24px;
    line-height: 24px;
    cursor: pointer;
  }
</style>
